const GuaranteesSkeleton = () => (
  <div className="flex flex-col gap-12">
    {Array(8)
      .fill(1)
      .map(() => (
        <div className="flex flex-wrap gap-6 max-w-4xl">
          <div className="animate-pulse h-8 w-full bg-gray-300 rounded" />
          <div className="animate-pulse h-20 w-full bg-gray-300 rounded" />
          <div className="animate-pulse h-8 w-full bg-gray-300 rounded" />
        </div>
      ))}
  </div>
);

export default GuaranteesSkeleton;
