import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ConsultationFile } from 'types/consultation';
import { Customer } from 'types/customer';
import { BookingConsultationOption } from 'types/lawyer';
import { Question } from 'types/question';

import {
  createConsultation,
  getConsultations,
  postAvailabilityRequest,
  rescheduleConsultation
} from './api';

export function useConsultationsQuery({
  customerId,
  enabled = true
}: {
  customerId: Customer['id'];
  enabled?: boolean;
}) {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['consultations'],
    queryFn: async () => {
      const consultations = await getConsultations(customerId);
      for (const consultation of consultations) {
        queryClient.setQueryData(
          ['consultation', consultation.id],
          consultation
        );
      }

      return consultations;
    },
    enabled
  });
}

// MUTATIONS
export function useCreateConsultationMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      questionId,
      availabilitySlotId,
      appointmentType = BookingConsultationOption.BookingPhone,
      comment = undefined,
      consultationFile = undefined
    }: {
      questionId: Question['id'];
      availabilitySlotId: string;
      appointmentType: BookingConsultationOption;
      comment?: string;
      consultationFile?: ConsultationFile;
    }) =>
      createConsultation(
        questionId,
        availabilitySlotId,
        appointmentType,
        comment,
        consultationFile
      ),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['consultations'] });
    }
  });
}

export function useRescheduleConsultationMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      consultationId,
      startAt
    }: {
      consultationId: number;
      startAt: Date;
    }) => rescheduleConsultation(consultationId, startAt),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['consultations'] });
    }
  });
}

export function useRequestNewLawyerAvailabilityMutation() {
  return useMutation({
    mutationFn: (questionId: number) => postAvailabilityRequest(questionId)
  });
}
