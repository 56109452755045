import { SVGProps } from 'types/core';

const HouseIcon = ({ ...props }: SVGProps) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 16 15"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.875 7.25C15.875 7.74219 15.4648 8.125 14.9727 8.125H14.0977V13.375C14.0977 13.8672 13.6875 14.25 13.2227 14.25H10.5977C10.1055 14.25 9.75 13.8672 9.75 13.375V10.3125C9.75 10.0938 9.53125 9.90234 9.3125 9.90234H6.6875C6.44141 9.90234 6.25 10.1211 6.25 10.3125V13.375C6.25 13.8672 5.83984 14.25 5.375 14.25H2.75C2.25781 14.25 1.875 13.8672 1.875 13.375V8.125H1C0.507812 8.125 0.125 7.74219 0.0976562 7.25C0.0976562 7.00391 0.207031 6.78516 0.398438 6.59375L7.39844 0.496094C7.5625 0.332031 7.78125 0.277344 7.97266 0.277344C8.16406 0.277344 8.38281 0.359375 8.54688 0.496094L15.5195 6.59375C15.7656 6.78516 15.875 7.00391 15.875 7.25Z"
      strokeWidth={1.5}
      stroke="black"
    />
  </svg>
);

export default HouseIcon;
