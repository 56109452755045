import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { FullCard, Title } from 'components';
import { ArticleListSkeleton } from 'components/skeleton';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  getMemberSuggestedArticles,
  useGetMemberSuggestedArticles
} from 'services/dato/articles';
import { getAuthenticatedMemberInfo } from 'services/member';

const SuggestedArticles = () => {
  const { t } = useTranslation('customer');
  const { data: member, isLoading: isMemberLoading } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  const {
    data: suggestedArticlesData,
    isLoading: isSuggestedArticlesLoading,
    isSuccess
  } = useQuery({
    queryKey: ['memberSuggestedArticles'],
    queryFn: getMemberSuggestedArticles
  });
  const hasSuggestedArticles =
    isSuccess &&
    suggestedArticlesData.ids !== null &&
    suggestedArticlesData.ids.length > 0;

  const { data, isLoading } = useGetMemberSuggestedArticles({
    articlesIds: suggestedArticlesData?.ids,
    isCustomerCSE: !!member?.is_active_cse_member,
    enabled: hasSuggestedArticles
  });

  if (isSuggestedArticlesLoading || isLoading || isMemberLoading)
    return <ArticleListSkeleton number={3} />;

  if (!hasSuggestedArticles) {
    return <div />;
  }

  return (
    <>
      <Title className="ml-1.5" variant="h2">
        {t('account.home.h2.suggested')}
      </Title>
      <div className="grid sm:grid-cols-4 lg:grid-cols-3 gap-2 mb-10">
        {data?.allArticles.map((article, index) => (
          <Link
            key={article.id}
            to={`/account/resources/${article.slug}`}
            className={classNames({
              'sm:col-span-4 lg:col-span-1': index === 0,
              'sm:col-span-2 lg:col-span-1': index !== 0
            })}
          >
            <FullCard
              imgHeight="h-48"
              title={article.title}
              image={article.featuredMedia?.responsiveImage}
              tags={[article.tags[0]?.name]}
            />
          </Link>
        ))}
      </div>
    </>
  );
};

export default SuggestedArticles;
