import ProfileMenu from 'components/ProfileMenu';
import { ProfileMenuSkeleton } from 'components/skeleton';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { getAuthenticatedMemberInfo } from 'services/member';

import { useAuth } from 'context';

import { HouseIcon } from 'features/shared/icons';
import GearIcon from 'features/shared/icons/GearIcon';

import { IMenuItem, PageName } from 'types/account';

const BASE_MENU_ITEMS: IMenuItem[] = [
  {
    name: PageName.Profile,
    href: '/account/profile',
    icon: GearIcon
  },
  {
    name: PageName.Admin,
    href: '/admin/collaborators'
  }
];

const MemberProfileMenu = () => {
  const { pathname } = useLocation();
  const { logout } = useAuth();
  const {
    data: member,
    isLoading,
    isSuccess
  } = useQuery(['authenticated-member-info'], () =>
    getAuthenticatedMemberInfo()
  );

  if (isLoading) return <ProfileMenuSkeleton />;
  if (!isSuccess) return <div />;

  const label =
    member.first_name && member.last_name
      ? `${member.first_name} ${member.last_name[0]}`
      : member.email;

  return (
    <ProfileMenu
      logout={logout}
      label={label}
      items={[
        ...BASE_MENU_ITEMS.filter(
          (item) => item.name !== PageName.Admin || member.is_admin
        ).map((item) => ({ ...item, current: pathname.includes(item.name) })),
        ...(pathname.includes(PageName.Admin)
          ? [
              {
                name: PageName.Home,
                href: '/account/home',
                icon: HouseIcon
              }
            ]
          : [])
      ]}
    />
  );
};

export default MemberProfileMenu;
