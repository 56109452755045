import { useInfiniteQuery } from 'react-query';

import { Videos } from 'types/cms';

import { graphQLRequest } from './dato';

export const YOUTUBE_VIDEOS_BY_PAGE = 10;

const GET_PAGINATED_VIDEOS_QUERY = `
  query GetVideos($skip: IntType, $first: IntType = 8) {
    allVideos(
      first: $first,
      skip: $skip
    ) {
      id
      video {
        thumbnailUrl
        title
        providerUid
      }
    }
  }
`;

export const useGetVideos = () =>
  useInfiniteQuery(
    ['videos'],
    ({ pageParam = 0 }): Promise<Videos | null> =>
      graphQLRequest(GET_PAGINATED_VIDEOS_QUERY, {
        skip: pageParam,
        first: YOUTUBE_VIDEOS_BY_PAGE
      }),
    {
      getNextPageParam: (_, pages) => {
        const nextCursor =
          pages.length === 1
            ? YOUTUBE_VIDEOS_BY_PAGE
            : pages.length * YOUTUBE_VIDEOS_BY_PAGE;

        return nextCursor;
      }
    }
  );
