import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Lawyer } from 'types/lawyer';

import {
  cancelAvailabilitySlot,
  createAvailabilitySlot,
  getLawyer
} from './api';

export function useLawyerQuery({ lawyerId }: { lawyerId: Lawyer['id'] }) {
  return useQuery(['lawyer', lawyerId], () => getLawyer(lawyerId));
}

export function useCreateAvailabilitySlotMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      lawyerId,
      startDate,
      endDate,
      appointmentOptions
    }: {
      lawyerId: Lawyer['id'];
      startDate: Date;
      endDate: Date;
      appointmentOptions: string[];
    }) =>
      createAvailabilitySlot(lawyerId, startDate, endDate, appointmentOptions),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['calendar-events']);
      }
    }
  );
}

export function useCancelAvailabilitySlotMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      lawyerId,
      startDate,
      endDate
    }: {
      lawyerId: Lawyer['id'];
      startDate: Date;
      endDate: String;
    }) => cancelAvailabilitySlot(lawyerId, startDate, endDate),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['calendar-events']);
      }
    }
  );
}
