import { useQuery } from '@tanstack/react-query';
import { Section, Title } from 'components';
import { isAfter, subDays } from 'date-fns';
import { uniq } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  AvailabilitySlotResponse,
  getQuestionAvailibilitySlots
} from 'services/availibility-slot';

import { useCustomerAccount, useGuardedSearchParams } from 'hooks';

import { LawyerAvailabilityRequestForm } from 'features/customer/account/consultations/booking';
import ConsultationBookingForm from 'features/customer/account/consultations/booking/steps/ConsultationBookingForm';
import ConsultationBookingSummary from 'features/customer/account/consultations/booking/steps/ConsultationBookingSummary';
import { ConsultationRescheduleBlockedBooking } from 'features/customer/account/consultations/reschedule/blocked';
import { PageLayout } from 'features/customer/account/content';
import { LoadingSpinner } from 'features/shared/loading';

import { getDateAsDdMmYyyy, getDateAsHhMm } from 'utils/date';

import { SetState } from 'types/core';
import { ConsultationSlot, LawyerAvailabilityDaySlots } from 'types/lawyer';

export enum BookingStep {
  Scheduling = 'scheduling',
  Summary = 'summary'
}

export function formatLawyerAvailabilitySlots(
  slots: AvailabilitySlotResponse['slots']
): LawyerAvailabilityDaySlots[] {
  const arrayOfDays = slots.map((slot) => getDateAsDdMmYyyy(slot.start_at));

  return uniq(arrayOfDays).map((day) => ({
    day,
    slots: slots
      .filter((slot) => getDateAsDdMmYyyy(slot.start_at) === day)
      .map((slot) => ({
        id: slot.id,
        startAt: slot.start_at,
        dayTime: getDateAsHhMm(slot.start_at)
      }))
  }));
}

export default function ConsultationBookingPage() {
  const { t } = useTranslation('customer');
  const questionId = useGuardedSearchParams({ param: 'questionId' });
  const [searchParams] = useSearchParams();
  const consultationId = searchParams.get('consultationId');
  const { consultations } = useCustomerAccount();

  const currentConsultation = consultations.find(
    (item) => item.id.toString() === consultationId
  );
  const appointmentId = searchParams.get('appointmentId');
  const [bookingStep, setBookingStep] = useState<BookingStep>(
    BookingStep.Scheduling
  );
  const [slot, setSlot] = useState<ConsultationSlot>();
  const { data, isSuccess } = useQuery({
    queryKey: ['question-availability-slot', questionId],
    queryFn: () => getQuestionAvailibilitySlots(Number(questionId))
  });

  const currentAppointment = currentConsultation?.appointments?.find(
    (item) => !item.isProcessed
  );

  const appointmentDate = new Date(currentAppointment?.appointmentDate || '');
  const isRescheduleBlocked = isAfter(new Date(), subDays(appointmentDate, 1));
  if (isRescheduleBlocked && appointmentId)
    return <ConsultationRescheduleBlockedBooking />;

  if (!isSuccess) {
    return (
      <div className="h-screen grid place-items-center">
        <LoadingSpinner large purple />
      </div>
    );
  }

  const isAttorneyAvailable = data.slots.length > 0;

  return (
    <PageLayout>
      <Section>
        <Title
          gutterBottom
          variant="h1"
          text={t('account.consultations.booking.title')}
        />
        {bookingStep === BookingStep.Scheduling && !isAttorneyAvailable && (
          <LawyerAvailabilityRequestForm questionId={Number(questionId)} />
        )}
        {bookingStep === BookingStep.Scheduling && isAttorneyAvailable && (
          <ConsultationBookingForm
            lawyerAvailabiltySlots={formatLawyerAvailabilitySlots(data.slots)}
            slot={slot as ConsultationSlot}
            setSlot={setSlot as SetState<ConsultationSlot>}
            setBookingStep={setBookingStep}
          />
        )}
        {bookingStep === BookingStep.Summary && isAttorneyAvailable && (
          <ConsultationBookingSummary
            slot={slot as ConsultationSlot}
            setBookingStep={setBookingStep}
          />
        )}
      </Section>
    </PageLayout>
  );
}
