import { privateAxios } from 'context/auth/auth.utils';

import {
  ApiConsultation,
  ApiConsultationConfirmation,
  Consultation,
  ConsultationFile
} from 'types/consultation';
import { Customer } from 'types/customer';
import { Question } from 'types/question';

import { apiPrivatePythonClient } from '../../../services/axios';
import { BookingConsultationOption } from '../../../types/lawyer';
import { formatConsultationFromApi } from './formatting';

export async function createConsultation(
  questionId: Question['id'],
  availabilitySlotId: string,
  appointmentType: BookingConsultationOption,
  comment?: string,
  consultationFile?: ConsultationFile
): Promise<ApiConsultationConfirmation> {
  const formData = new FormData();
  formData.append('questionId', questionId);
  formData.append('availabilitySlotId', availabilitySlotId);
  formData.append('appointmentType', appointmentType);
  if (comment) {
    formData.append('comment', comment);
  }
  if (consultationFile?.content) {
    formData.append(
      'document',
      new Blob([consultationFile.content]),
      consultationFile.name
    );
  }

  const res = await privateAxios.post(
    `/v2/questions/${questionId}/consultations`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  const consultation = res.data;
  return consultation;
}

export async function rescheduleConsultation(
  consultationId: number,
  startAt: Date
): Promise<void> {
  await apiPrivatePythonClient.put<ApiConsultationConfirmation>(
    `/member/consultations/${consultationId}`,
    {
      start_at: startAt
    }
  );
}

export async function getConsultations(
  customerId: Customer['id']
): Promise<Consultation[]> {
  const res = await privateAxios.get<Consultation[]>(
    `/consultations?customer.id=${customerId}`
  );
  return formatConsultationFromApi(res.data as ApiConsultation[]);
}

export async function postAvailabilityRequest(
  questionId: number
): Promise<void> {
  await apiPrivatePythonClient.post(`/member/availability-requests`, {
    question_id: questionId
  });
}
