import { apiPublicPythonClient } from 'services/axios';

export interface AuthLoginResponse {
  access_token: string;
  refresh_token: string;
}

export type AuthLoginPayload =
  | { email: string; password: string; token?: null }
  | { token: string; email?: null; password?: null };

export const loginByPasswordOrMagicLink = async ({
  email,
  password,
  token
}: AuthLoginPayload): Promise<AuthLoginResponse> => {
  const { data } = await apiPublicPythonClient.post<AuthLoginResponse>(
    `/auth/login`,
    { email, password, magic_link_token: token },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  return data;
};
