import * as Sentry from '@sentry/react';
import App from 'app';
import 'index.css';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

import AppProviders from 'context/AppProviders';

Sentry.init({
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  dsn: process.env.REACT_APP_SENTRY_DSN_URL,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    })
  ],
  tracesSampleRate: 1.0
});

const element = document.getElementById('root');

createRoot(element!).render(
  <AppProviders>
    <App />
  </AppProviders>
);
