import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { MemberConsultation } from 'services/consultation';
import {
  Note,
  UnsatisfiedConsultationReasons,
  isPositiveRating
} from 'services/rating';

import { useCreateUnauthenticatedAppointmentVoteMutation } from 'api/customer/appointments';

import { Toast } from 'features/shared/toasts';

import { VotingStatus } from './RateAnswer';
import { ConsultationVoteChoice, ConsultationVoteReason } from './components';

interface RateConsultationProps {
  consultation: MemberConsultation;
}

export interface RateConsultationInput {
  note: Note;
  reason?: UnsatisfiedConsultationReasons | undefined;
}

export default function RateConsultation({
  consultation
}: RateConsultationProps) {
  const { t } = useTranslation('customer');
  const queryClient = useQueryClient();
  const [answerVotingStatus, setAnswerVotingStatus] =
    useState<VotingStatus>('non-voted');
  const [note, setNote] = useState<RateConsultationInput['note']>();

  const { mutate, isPending: isCreateAppointmentVoteLoading } =
    useCreateUnauthenticatedAppointmentVoteMutation();

  const onSubmit = (data: RateConsultationInput) => {
    mutate(
      {
        ratingLinkId: consultation?.rating_link_id as string,
        ...data
      },
      {
        onError: () => {
          toast.custom(
            <Toast
              type="error"
              title={t('general.error')}
              message={t('account.rateAnswer.form.error')}
            />
          );
        },
        onSuccess: () => {
          toast.custom(
            <Toast
              type="success"
              title={t('general.success')}
              message={t('account.vote.success')}
            />
          );
          setAnswerVotingStatus('submitted');
          queryClient.invalidateQueries({
            queryKey: ['member-consultation', consultation.id]
          });
        }
      }
    );
  };

  const onNoteClick = (value: Note) => {
    if (isPositiveRating(value)) {
      return onSubmit({ note: value });
    }

    setNote(value);
    return setAnswerVotingStatus('unsatisfied');
  };

  return (
    <>
      {answerVotingStatus === 'non-voted' && (
        <ConsultationVoteChoice handleConsultationVote={onNoteClick} />
      )}
      {answerVotingStatus === 'unsatisfied' && (
        <ConsultationVoteReason
          handleSubmitUnsatisfiedVote={(reason) => {
            note && onSubmit({ note, reason });
          }}
          isMutationLoading={isCreateAppointmentVoteLoading}
        />
      )}
    </>
  );
}
