import classNames from 'classnames';
import { Title } from 'components';
import { PropsWithChildren } from 'react';

interface SectionProps {
  title?: string;
  extra?: React.ReactNode;
  className?: string;
  color?: string;
  gutterBottom?: boolean;
}

const Section = ({
  children,
  title,
  className,
  extra,
  color = 'gray-100',
  gutterBottom = false
}: PropsWithChildren<SectionProps>) => (
  <div
    className={classNames(
      `rounded-2xl bg-${color} px-3 py-4 md:py-5 md:px-8`,
      className,
      gutterBottom && 'mb-4'
    )}
  >
    <div className="flex flex-col gap-6 md:flex-row">
      {title && (
        <Title variant="h2" gutterBottom>
          {title}
        </Title>
      )}
      {extra && <div className="hidden md:block mt-1.5">{extra}</div>}
    </div>
    {children}
  </div>
);

export default Section;
