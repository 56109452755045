import * as Sentry from '@sentry/react';
import { useQueryClient, useQuery as useQueryV2 } from '@tanstack/react-query';
import jwtDecode from 'jwt-decode';
import { FC, createContext, useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  AuthenticatedMemberInfo,
  getAuthenticatedMemberInfo
} from 'services/member';
import { useLocalStorage } from 'usehooks-ts';

import { getAccount } from 'api/shared/account/api';

import { AuthLocalStorage } from 'context/auth/auth.utils';

import { LayoutLoadingSkeleton } from 'features/shared/layout';

import { Lawyer } from 'types/lawyer';

type UserType = 'member' | 'lawyer';

export interface AuthContext {
  lawyerId: number | null;
  lawyer: Lawyer | null;
  roles: string[];
  userId: number | null | undefined;
  userType: UserType | null;
  email: string | null;
  accessToken: AuthLocalStorage['accessToken'] | null;
  refreshToken: AuthLocalStorage['refreshToken'] | null;
  setAccessToken: React.Dispatch<
    React.SetStateAction<AuthLocalStorage['accessToken']>
  >;
  setRefreshToken: React.Dispatch<
    React.SetStateAction<AuthLocalStorage['refreshToken']>
  >;
  logout: () => void;
  member?: AuthenticatedMemberInfo;
}

const AuthCtx = createContext<AuthContext | undefined>(undefined);

type Jwt = {
  sub: string;
  user_type: UserType;
};

const AuthProvider: FC = ({ children }) => {
  const clientQuery = useQueryClient();
  // This 4 useState will be removed when getAuthenticatedLawyerInfo will be implemented
  const [roles, setRoles] = useState<string[]>([]);
  const [lawyerId, setLawyerId] = useState<number | null>(null);
  const [lawyer, setLawyer] = useState<Lawyer | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const [accessToken, setAccessToken, removeAccessToken] = useLocalStorage<
    AuthLocalStorage['accessToken']
  >('accessToken', '', {
    deserializer: (value) => value,
    serializer: (value) => value
  });
  const [refreshToken, setRefreshToken, removeRefreshToken] = useLocalStorage<
    AuthLocalStorage['refreshToken']
  >('refreshToken', '', {
    deserializer: (value) => value,
    serializer: (value) => value
  });

  const { sub: email = null, user_type: userType = null } = accessToken
    ? jwtDecode<Jwt>(accessToken)
    : {};

  const { data: member, isLoading } = useQueryV2({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo(),
    enabled: userType === 'member'
  });

  // should be replaced by getAuthenticatedLawyerInfo
  const account = useQuery(
    ['account', email],
    () => getAccount(email as string),
    {
      enabled: userType === 'lawyer',
      onSuccess: (a) => {
        setRoles(a.roles);
        setLawyerId(a.lawyerId);
        setUserId(a.id);
        setLawyer(a.lawyer);
      }
    }
  );

  const logout = () => {
    clientQuery.invalidateQueries({
      queryKey: ['authenticated-member-info'],
      type: 'all'
    });
    removeAccessToken();
    removeRefreshToken();
    Sentry.getCurrentScope().setUser(null);
  };

  const value = useMemo(
    () => ({
      lawyerId,
      lawyer,
      setAccessToken,
      accessToken,
      refreshToken,
      setRefreshToken,
      roles,
      member,
      userType,
      email,
      userId,
      logout
    }),
    [lawyerId, lawyer, roles, member, accessToken, refreshToken]
  );

  if (account.isLoading || isLoading) return <LayoutLoadingSkeleton />;

  return <AuthCtx.Provider value={value}>{children}</AuthCtx.Provider>;
};

const useAuth = () => {
  const context = useContext(AuthCtx);
  if (context === undefined) {
    throw new Error('useAuth must be used within a Provider');
  }

  return context;
};

export { AuthProvider, AuthCtx, useAuth };
