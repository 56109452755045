import { Divider } from 'components';
import { Card, CardContent } from 'components/card';
import { MemberQuestionAdditionalRequest } from 'services/question';

import { useLocale } from 'hooks';

import SanitizedContent from 'features/customer/question/SanitizedContent';
import LawyerIdentity from 'features/lawyer/components/LawyerIdentity';

import { formatDateToIntl } from 'utils/date';

interface AdditionalRequestAnswerCardProps {
  additionalRequest: MemberQuestionAdditionalRequest;
}

const AdditionalRequestAnswerCard = ({
  additionalRequest
}: AdditionalRequestAnswerCardProps) => {
  const { locale } = useLocale();

  return (
    <Card variant="secondary" className="mt-4">
      <CardContent>
        <LawyerIdentity
          fullName={additionalRequest.lawyer_full_name}
          avatarUrl={additionalRequest.lawyer_avatar_url}
        />
        <Divider />
        {additionalRequest.request_answer && (
          <SanitizedContent content={additionalRequest.request_answer} />
        )}
        {additionalRequest.answered_at && (
          <div className="mt-2 text-right text-sm font-medium text-gray-700">
            {formatDateToIntl(
              new Date(additionalRequest.answered_at),
              'long',
              locale
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default AdditionalRequestAnswerCard;
