import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { translateAnswer } from 'api/lawyer/answers/api';

import SanitizedContent from 'features/customer/question/SanitizedContent';
import { LoadingSpinner } from 'features/shared/loading';
import { Toast } from 'features/shared/toasts';

interface TranslatedAnswerProps {
  answer: string;
}

const TranslatedAnswer = ({ answer }: TranslatedAnswerProps) => {
  const { t } = useTranslation('lawyer');
  const {
    mutate: translateAnswerMutate,
    data: translateAnswerData,
    error
  } = useMutation((data: string) => translateAnswer(data), {
    onError: () => {
      toast.custom(
        <Toast
          type="error"
          title={t('general.error')}
          message={t('account.lawyer.question.answer.translate.error')}
        />,
        { position: 'top-right', duration: 3000 }
      );
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => translateAnswerMutate(answer), []);

  if (!translateAnswerData) return <LoadingSpinner />;
  if (error)
    return <div>{t('account.lawyer.question.answer.translate.error')}</div>;

  return <SanitizedContent content={translateAnswerData.translatedContent} />;
};

export default TranslatedAnswer;
