import { Title } from 'components';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Lawyer } from 'services/question';

const LawyerIdentity = ({
  fullName,
  avatarUrl
}: {
  fullName: Lawyer['full_name'] | null;
  avatarUrl?: Lawyer['avatar_url'] | null;
}) => {
  const { t } = useTranslation('customer');

  return (
    <div className="flex items-center">
      <div className="mr-2 flex-shrink-0 flex items-center">
        {!fullName && (
          <span className="inline-block h-6 w-6 overflow-hidden rounded-full bg-gray-100">
            <svg
              className="h-full w-full text-gray-300"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
        )}
        {fullName && avatarUrl && (
          <img
            className="h-6 w-6 rounded-full"
            src={avatarUrl}
            alt={fullName}
          />
        )}
        {fullName && !avatarUrl && (
          <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-500">
            <span className="text-sm font-medium leading-none text-white">
              {capitalize(fullName)[0]}
            </span>
          </span>
        )}
      </div>
      <Title variant="h4" component="h3">
        {fullName
          ? t('account.question.answer.title', {
              lawyerFullName: fullName
            })
          : t('account.question.answer.ekielegalteam')}
      </Title>
    </div>
  );
};

export default LawyerIdentity;
