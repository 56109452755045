import { LABOR_LABEL_THEMATIC_SLUG } from 'constants/thematics';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getConsumerCategorieBySlug } from 'services/consumerCategorie';
import { getAuthenticatedMemberInfo } from 'services/member';

import { useSubscriptionQuery } from 'api/customer/subscriptions';

import { Alert } from 'features/shared/alerts';

const LaborLawAskQuestionWarning = (): ReactElement | null => {
  const { t } = useTranslation('customer');
  const { data: member, isSuccess: isMemberSuccess } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  // crappy code, to remove when we can handle forbidden thematics properly
  const { data: subscriptionData } = useSubscriptionQuery({
    customerId: member?.id || 0,
    enabled: isMemberSuccess
  });
  const { data: consumerCategorieData } = useQuery(
    ['consumerCategorieBySlug', LABOR_LABEL_THEMATIC_SLUG],
    () => getConsumerCategorieBySlug(LABOR_LABEL_THEMATIC_SLUG)
  );

  if (!subscriptionData || isMemberSuccess || !consumerCategorieData)
    return null;

  const forbiddenTagIds = subscriptionData.restrictions.thematicsForbidden;

  const consumerTagsIds = consumerCategorieData.consumerTags.map(
    (consumerTag) => consumerTag.replace('/consumer-tags/', '')
  );

  const hasLaborLawForbidden = consumerTagsIds.every((value) =>
    forbiddenTagIds.includes(value)
  );

  if (!hasLaborLawForbidden) return null;

  return (
    <Alert type="info" className="mb-4">
      {t('account.subscription.banner.info')}
    </Alert>
  );
};

export default LaborLawAskQuestionWarning;
