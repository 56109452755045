import classNames from 'classnames';
import { PropsWithChildren } from 'react';

interface CardProps {
  className?: string;
  compact?: boolean;
  variant?: 'primary' | 'secondary' | 'transparent';
}

const Card = ({
  className,
  children,
  variant = 'primary',
  compact = false
}: PropsWithChildren<CardProps>) => (
  <div
    className={classNames(
      'rounded-lg flex gap-2 flex-col w-full ',
      compact ? 'p-3' : 'p-4',
      {
        'bg-white border border-gray-300': variant === 'primary',
        'bg-gray-50 bg-opacity-45 border border-gray-300':
          variant === 'secondary',
        'hover:bg-gray-50': variant === 'transparent'
      },
      className
    )}
  >
    {children}
  </div>
);

export default Card;
