import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AppRouter, LawyerRouter } from 'routes';

import { UnavailablePage } from 'pages/error';

import { useAuth } from 'context/index';

import './i18n/config';

function App() {
  const { userType } = useAuth();
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [location, i18n]);

  if (process.env.REACT_APP_EKIE_AVAILIBILITY === '0') {
    return <UnavailablePage />;
  }

  if (userType === 'lawyer') {
    return <LawyerRouter />;
  }

  return <AppRouter />;
}

export default Sentry.withProfiler(App);
