import {
  AnnotationIcon,
  CalendarIcon,
  ChatAlt2Icon
} from '@heroicons/react/outline';
import { ErrorBoundary } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { Card, GoogleRating, Section, Tag } from 'components';
import { CardContent } from 'components/card';
import FeatureErrorCard from 'components/card/FeatureErrorCard';
import QuestionSectionSkeleton from 'components/skeleton/QuestionSectionSkeleton';
import { Fade } from 'components/transition';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Lawyer, getMemberQuestion } from 'services/question';

import { useLocale } from 'hooks';

import QuestionAnswer from 'features/answer/QuestionAnswer';
import MemberConsultation from 'features/consultation/MemberConsultation';
import BookConsultationCard from 'features/consultation/components/BookConsultationCard';
import RateAnswer from 'features/rating/RateAnswer';
import { Paragraph } from 'features/shared/body';

import { formatDateToIntl } from 'utils/date';

import { isPositiveRating } from '../../services/rating';
import MemberQuestionAdditionalRequest from './MemberQuestionAdditionalRequest';
import MemberQuestionShortcut from './MemberQuestionShortcut';
import { RequestLayout, RequestStepLayout } from './components';
import MemberQuestionCard from './components/MemberQuestionCard';

interface MemberQuestionProps {
  questionId: number;
}

const MemberQuestion = ({ questionId }: MemberQuestionProps) => {
  const { t } = useTranslation('customer');
  const { locale } = useLocale();
  const [showGoogleRating, setShowGoogleRating] = useState<boolean>(false);

  const {
    data: question,
    isLoading,
    isSuccess
  } = useQuery({
    queryKey: ['member-question', questionId],
    queryFn: () => getMemberQuestion(questionId),
    refetchOnWindowFocus: true
  });

  if (isLoading || !isSuccess) return <QuestionSectionSkeleton />;
  const questionDate = formatDateToIntl(
    new Date(question.created_at),
    'long',
    locale
  );
  const emptyTitlePlaceholder = `${t('account.request.of')} ${questionDate}`;

  const hasActions = question.can_share_question || question.can_rate_answer;

  return (
    <Fade>
      <Section
        extra={
          <Tag
            variant={
              ['archived', 'consultation_cancelled'].includes(question.status)
                ? 'error'
                : 'success'
            }
          >
            {t(`account.request.status.${question.status}`)}
          </Tag>
        }
        title={emptyTitlePlaceholder}
        color="white"
      >
        <div className="grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-12">
          <div className="space-y-6 lg:col-span-8 ml-2 md:ml-0">
            {showGoogleRating && (
              <Card>
                <CardContent>
                  <GoogleRating />
                </CardContent>
              </Card>
            )}
            <RequestLayout>
              {question.consultation_id && question.lawyer && (
                <RequestStepLayout icon={CalendarIcon}>
                  {({ setType }) => (
                    <MemberConsultation
                      consultationId={
                        question.consultation_id as NonNullable<number>
                      }
                      lawyer={question.lawyer as NonNullable<Lawyer>}
                      onGetMemberConsultation={(consultation) =>
                        consultation.status === 'status_cancelled' &&
                        setType('error')
                      }
                    />
                  )}
                </RequestStepLayout>
              )}
              {question.additional_request_id && (
                <RequestStepLayout icon={ChatAlt2Icon}>
                  <MemberQuestionAdditionalRequest
                    additionalRequestId={question.additional_request_id}
                  />
                </RequestStepLayout>
              )}
              {question.can_ask_consultation && (
                <RequestStepLayout defaultType="action">
                  <BookConsultationCard
                    questionId={question.id}
                    lawyerId={question.lawyer.id}
                  />
                </RequestStepLayout>
              )}
              {question.answer_id && (
                <RequestStepLayout icon={ChatAlt2Icon}>
                  <ErrorBoundary
                    fallback={
                      <FeatureErrorCard
                        title={t('account.question.answer.error.title')}
                      />
                    }
                  >
                    <QuestionAnswer
                      answerId={question.answer_id}
                      lawyer={question.lawyer}
                      footer={
                        question.can_rate_answer && (
                          <ErrorBoundary
                            fallback={
                              <FeatureErrorCard
                                title={t('account.question.vote.error.title')}
                              />
                            }
                          >
                            <RateAnswer
                              answerId={question.answer_id}
                              onRateSubmitted={(note) => {
                                if (isPositiveRating(note)) {
                                  setShowGoogleRating(true);
                                }
                              }}
                            />
                          </ErrorBoundary>
                        )
                      }
                    />
                  </ErrorBoundary>
                </RequestStepLayout>
              )}
              {question.assigned_at && (
                <RequestStepLayout defaultType="info">
                  <Card compact>
                    <Paragraph className="!text-sm italic">
                      <Trans
                        i18nKey="account.request.assigned.label"
                        ns="customer"
                      />
                    </Paragraph>
                  </Card>
                </RequestStepLayout>
              )}
              {question.status === 'archived' && (
                <RequestStepLayout defaultType="error">
                  <Card compact>
                    <Paragraph className="!text-sm italic font-bold">
                      <Trans
                        i18nKey="account.request.archived.label"
                        ns="customer"
                        components={{ b: <strong /> }}
                      />
                    </Paragraph>
                  </Card>
                </RequestStepLayout>
              )}
              <RequestStepLayout defaultType="info" className="mb-8">
                <Card compact>
                  <Paragraph className="!text-sm italic">
                    <Trans
                      i18nKey="alert.privateQuestion.paragraph"
                      ns="customer"
                      components={{ b: <strong /> }}
                    />
                  </Paragraph>
                </Card>
              </RequestStepLayout>
              <RequestStepLayout icon={AnnotationIcon}>
                <MemberQuestionCard question={question} />
              </RequestStepLayout>
            </RequestLayout>
          </div>
          <div className="lg:col-span-4">
            {hasActions && (
              <MemberQuestionShortcut
                questionId={questionId}
                setShowGoogleRating={setShowGoogleRating}
              />
            )}
          </div>
        </div>
      </Section>
    </Fade>
  );
};

export default MemberQuestion;
