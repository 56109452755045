import classNames from 'classnames';
import { PropsWithChildren } from 'react';

interface TitleProps {
  variant: 'h1' | 'h2' | 'h3' | 'h4';
  component?: 'h1' | 'h2' | 'h3' | 'h4';
  text?: string;
  italic?: boolean;
  uppercase?: boolean;
  className?: string;
  gutterBottom?: boolean;
}

export default function Title({
  variant,
  component,
  children,
  text,
  uppercase = false,
  italic = false,
  gutterBottom = false,
  className = ''
}: PropsWithChildren<TitleProps>) {
  const Component = component || variant;

  return (
    <Component
      className={classNames(
        {
          'text-2xl sm:text-3xl font-bold font-sans': variant === 'h1',
          'text-2xl tracking-wide font-sans font-bold mb-4 text-black':
            variant === 'h2',
          'text-lg font-sans font-medium leading-6': variant === 'h3',
          'text-base font-sans font-medium leading-6': variant === 'h4',
          italic,
          uppercase
        },
        gutterBottom && 'pb-2',
        className
      )}
    >
      {text || children}
    </Component>
  );
}
