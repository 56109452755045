import { useCustomerAccount } from 'hooks';

import {
  SubscriptionDetails,
  SubscriptionStatus
} from 'features/customer/account/subscription';

function CustomerSubscription() {
  const { subscription } = useCustomerAccount();

  const isSubscriptionActive =
    !!subscription &&
    subscription?.active &&
    subscription?.status !== 'terminated';

  return (
    <div className="max-w-[720px]">
      <div className="mb-6">
        <SubscriptionStatus subscription={subscription} />
      </div>
      {isSubscriptionActive && (
        <div className="mb-6">
          <SubscriptionDetails subscription={subscription} />
        </div>
      )}
    </div>
  );
}

export default CustomerSubscription;
