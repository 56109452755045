import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { MemberProtectedRoute, PublicRoute } from 'routes';

import {
  LoginMagicLinkPage,
  LoginPage,
  RequestResetPasswordPage,
  ResetPasswordPage,
  SignUpPage
} from 'pages/authentication';
import {
  ConsultationAppointmentVotePage,
  ConsultationBookingPage
} from 'pages/consultation';
import {
  AccountPage,
  AdministratorsPage,
  AlphabetSheetPage,
  ArticlePage,
  CollaboratorsPage,
  ConsultationsPage,
  ContactPage,
  HomePage,
  NpsVotePage,
  PrivateContentPage,
  ResourcesPage,
  ThematicsPage,
  UpdatePasswordPage,
  VideoPage
} from 'pages/customer';
import AdminPage from 'pages/customer/AdminPage';
import {
  PasswordCompletionPage,
  ProfileCompletionPage,
  SituationCompletionPage,
  ThematicsCompletionPage
} from 'pages/customer/onboarding';
import { NotFoundPage } from 'pages/error';
import { ProfilePage } from 'pages/member';
import {
  AskQuestionPage,
  CseQuestionsPage,
  MemberQuestionPage,
  MemberQuestionsConsultationAvailablePage,
  PersonalQuestionsPage
} from 'pages/questions';
import { AnswerRatingPage } from 'pages/rating';

import { MEMBER_DEFAULT_REDIRECT_URL } from './constants';

export const RedirectToRateAnswer = () => {
  const { ratingLinkId } = useParams();

  return <Navigate to={`/rate-answer/${ratingLinkId}`} replace />;
};

const AppRouter = () => (
  <Routes>
    <Route element={<PublicRoute />}>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="authenticate" element={<LoginMagicLinkPage />} />
      <Route path="signup" element={<SignUpPage />} />
      <Route path="password/request" element={<RequestResetPasswordPage />} />
      <Route path="password/new" element={<ResetPasswordPage />} />
    </Route>
    <Route element={<MemberProtectedRoute admin />}>
      <Route path="admin" element={<AdminPage />}>
        <Route path="collaborators" element={<CollaboratorsPage />} />
        <Route path="settings" element={<AdministratorsPage />} />
      </Route>
    </Route>
    <Route element={<MemberProtectedRoute cse />}>
      <Route path="account" element={<AccountPage />}>
        <Route path="questions-cse" element={<CseQuestionsPage />} />
      </Route>
    </Route>
    <Route element={<MemberProtectedRoute />}>
      <Route path="account" element={<AccountPage />}>
        <Route path="ask" element={<AskQuestionPage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="home" element={<HomePage />} />
        <Route
          path="dashboard"
          element={<Navigate replace to={MEMBER_DEFAULT_REDIRECT_URL} />}
        />
        <Route path="questions" element={<PersonalQuestionsPage />} />
        <Route path="consultations" element={<ConsultationsPage />} />
        <Route path="guarantees" element={<ThematicsPage />} />
        <Route path="resources" element={<ResourcesPage />} />
        <Route path="resources/videos" element={<ResourcesPage />} />
        <Route path="videos/:id" element={<VideoPage />} />
        <Route path="resources/documents" element={<ResourcesPage />} />
        <Route path="resources/alphabet" element={<ResourcesPage />} />
        <Route path="resources/:slug" element={<ArticlePage />} />
        <Route path="alphabet/:slug" element={<AlphabetSheetPage />} />
        <Route
          path="consultations/booking"
          element={<ConsultationBookingPage />}
        />
        <Route path="contact" element={<ContactPage />} />
        <Route path="password-new" element={<UpdatePasswordPage />} />
      </Route>
      <Route path="questions" element={<AccountPage />}>
        <Route path=":questionId" element={<MemberQuestionPage />} />
        <Route
          path="consultation-available"
          element={<MemberQuestionsConsultationAvailablePage />}
        />
      </Route>
      <Route path="onboarding/profile" element={<ProfileCompletionPage />} />
      <Route path="onboarding/password" element={<PasswordCompletionPage />} />
      <Route
        path="onboarding/situation"
        element={<SituationCompletionPage />}
      />
      <Route
        path="onboarding/thematics"
        element={<ThematicsCompletionPage />}
      />
    </Route>
    <Route
      path="onboarding/email"
      element={<Navigate to={MEMBER_DEFAULT_REDIRECT_URL} replace />}
    />

    <Route path="nps/vote/:accountId" element={<NpsVotePage />} />
    <Route path="content/:slug" element={<PrivateContentPage />} />
    <Route
      path="questions/vote/link/:ratingLinkId"
      element={<RedirectToRateAnswer />}
    />
    <Route path="rate-answer/:ratingLinkId" element={<AnswerRatingPage />} />
    <Route
      path="consultations/vote/link/:voteLinkId"
      element={<ConsultationAppointmentVotePage />}
    />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default AppRouter;
