import { useQuery } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';
import { getAuthenticatedMemberInfo } from 'services/member';

import { useSubscriptionQuery } from 'api/customer/subscriptions';

import AdminNav from 'features/customer/account/nav/AdminNav';
import { LayoutLoadingSkeleton } from 'features/shared/layout';

export default function AdminPage() {
  const {
    data: member,
    isLoading: isMemberLoading,
    isSuccess
  } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  const subscription = useSubscriptionQuery({
    customerId: member?.id || 0,
    enabled: isSuccess
  });

  if (!subscription.isSuccess || isMemberLoading)
    return <LayoutLoadingSkeleton />;

  return (
    <div className="min-h-screen bg-gray-200 md:flex">
      <div
        id="sidebar"
        className="md:my-4 md:ml-2 rounded-2xl bg-white md:w-[256px] md:min-w-[256px]"
      >
        <AdminNav />
      </div>
      <div
        id="content"
        className="mt-[72px] md:mt-0 md:col-span-2 bg-gray-200 z-10 pt-4 md:pt-4 pb-6 w-full"
      >
        <Outlet
          context={{
            subscription: subscription.data
          }}
        />
      </div>
    </div>
  );
}
