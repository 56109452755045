import { Divider } from 'components';
import { Card, CardContent } from 'components/card';
import { useTranslation } from 'react-i18next';
import { MemberQuestion } from 'services/question';

import { useLocale } from 'hooks';

import { formatDateToIntl } from 'utils/date';

import SanitizedContent from '../../customer/question/SanitizedContent';

interface MemberQuestionCardProps {
  question: MemberQuestion;
}

const MemberQuestionCard = ({ question }: MemberQuestionCardProps) => {
  const { t } = useTranslation('customer');
  const { locale } = useLocale();

  return (
    <Card variant="secondary">
      <CardContent>
        <div className="sm:col-span-2">
          <p className="font-medium">{t('account.request.asked')}</p>
          <Divider />
          <div className="text-sm mt-1">
            <SanitizedContent content={question.content} />
          </div>
        </div>
        <div className="mt-2 text-right text-sm font-medium text-gray-700">
          {formatDateToIntl(new Date(question.created_at), 'long', locale)}
        </div>
      </CardContent>
    </Card>
  );
};

export default MemberQuestionCard;
