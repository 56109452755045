/* eslint-disable react-hooks/exhaustive-deps */
import FormHelper from 'components/FormHelper';
import CreateAdditionalRequestAnswer from 'containers/additional-request/CreateAdditionalRequestAnswer';
import AnswerHelperVote from 'containers/answer/AnswerHelperVote';
import { formatISO } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useLawyerAccount } from 'hooks';

import { useAuth } from 'context';

import AnswerCard from 'features/answer/AnswerCard';
import { BackQuestions } from 'features/lawyer/account/questions/nav';
import {
  AdditionalRequestCard,
  PreviousAnswerCard,
  QuestionCard,
  QuestionTitle
} from 'features/lawyer/account/questions/to-answer';

import { Answer, AnswerStatus } from 'types/answer';

import CreateEditAnswer from './CreateEditAnswer';

export default function ToAnswerPage() {
  const { lawyerId, lawyer } = useAuth();
  const { t } = useTranslation('lawyer');

  const [searchParams] = useSearchParams();
  const { questionsToAnswer } = useLawyerAccount();

  const question = questionsToAnswer.find(
    (q) => q.id === searchParams.get('id')
  );

  const lastDraftAnswer = useMemo(() => {
    if (question?.answers.length === 0) return undefined;
    const draftAnswers = question?.answers?.filter(
      (a) => a.status === AnswerStatus.DRAFT
    );

    if (!draftAnswers) return undefined;
    if (draftAnswers.length === 0) return undefined;
    const correspondingDraft = draftAnswers.reduce((a, b) =>
      a.createdDate > b.createdDate ? a : b
    );

    return correspondingDraft;
  }, [questionsToAnswer, searchParams]);

  const previousAnswers = useMemo(() => {
    if (question) {
      const hasAnswer = question.answers.filter(
        (a: any) =>
          a.lawyer.id === lawyerId && a.status === AnswerStatus.REJECTED
      );
      return hasAnswer;
    }
    return [];
  }, [questionsToAnswer, searchParams]);

  const publishedAnswer = question?.answers.find(
    (a) => a.status === AnswerStatus.PUBLISHED
  );

  return (
    <div className="overflow-y-auto max-h-screen">
      {!!question && (
        <>
          <div className="z-40 sticky top-0 bg-gray-paper">
            <div className="pt-6 lg:pt-10 pb-6 px-6 border-b border-gray-500">
              <div className="block pb-4 lg:hidden lg:pb-0">
                <BackQuestions />
              </div>
              <QuestionTitle question={question} />
            </div>
          </div>
          <div className="container mx-auto">
            <div className="flex flex-col gap-4 p-6">
              <QuestionCard question={question} />
              {previousAnswers.map((answer: Answer) => (
                <PreviousAnswerCard key={answer.id} answer={answer} />
              ))}
              {publishedAnswer ? (
                <>
                  <AnswerCard
                    lawyerFullName={
                      lawyer ? `${lawyer.firstName} ${lawyer.lastName}` : null
                    }
                    answer={{
                      ...publishedAnswer,
                      published_at: formatISO(
                        new Date(
                          publishedAnswer.publishedDate ||
                            publishedAnswer.createdDate
                        )
                      ),
                      rating_link_id: null
                    }}
                  />
                  {publishedAnswer.additionalRequest && (
                    <AdditionalRequestCard
                      customer={question.customer}
                      additionalRequest={publishedAnswer.additionalRequest}
                    />
                  )}
                  {publishedAnswer.additionalRequest &&
                    !publishedAnswer.additionalRequest.answeredAt && (
                      <CreateAdditionalRequestAnswer
                        answerId={publishedAnswer.id}
                        additionalRequestId={
                          publishedAnswer.additionalRequest.id
                        }
                      />
                    )}
                </>
              ) : (
                <>
                  {lawyer?.isEligibleForAnswerHelper &&
                  question.answerHelper ? (
                    <FormHelper
                      title={t('account.lawyer.question.answer.helper.title')}
                    >
                      {question.answerHelper.answerSuggestion}
                      <AnswerHelperVote
                        defaultValue={
                          question.answerHelper.upVote !== null
                            ? question.answerHelper.upVote
                            : undefined
                        }
                        answerHelperId={question.answerHelper.id}
                      />
                    </FormHelper>
                  ) : (
                    <FormHelper
                      title={t(
                        'account.lawyer.question.answer.advices.howTo.title'
                      )}
                      items={[
                        t(
                          'account.lawyer.question.answer.advices.howTo.advice1'
                        ),
                        t(
                          'account.lawyer.question.answer.advices.howTo.advice2'
                        ),
                        t(
                          'account.lawyer.question.answer.advices.howTo.advice3'
                        ),
                        t(
                          'account.lawyer.question.answer.advices.howTo.advice4'
                        )
                      ]}
                    />
                  )}
                  <CreateEditAnswer
                    question={question}
                    questionsToAnswer={questionsToAnswer}
                    lastDraftAnswer={lastDraftAnswer}
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
