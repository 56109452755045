import { Section, Title } from 'components';
import { Fade } from 'components/transition';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCustomerAccount } from 'hooks';

import ConsultationCard from 'features/customer/account/consultations/ConsultationCard';
import { PageLayout } from 'features/customer/account/content';
import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';

import { ConsultationStatus } from 'types/consultation';

export default function ConsultationsPage() {
  const { t } = useTranslation('customer');
  const { consultations } = useCustomerAccount();

  const futureConsultations = sortBy(
    consultations.filter((c) =>
      [
        ConsultationStatus.PENDING,
        ConsultationStatus.VALIDATED,
        ConsultationStatus.QUALIFIED,
        ConsultationStatus.SCHEDULED,
        ConsultationStatus.SCHEDULED_APPOINTMENT,
        ConsultationStatus.HAS_INCIDENT,
        ConsultationStatus.TO_RESCHEDULE
      ].includes(c.status)
    ),
    'appointments[0].appointmentDate',
    'asc'
  ).reverse();

  const hasFutureConsultations = Boolean(futureConsultations.length);

  const pastConsultations = sortBy(
    consultations.filter((c) => c.status === ConsultationStatus.COMPLETED),
    'appointments[0].appointmentDate'
  ).reverse();

  const hasPastConsultations = Boolean(pastConsultations.length);

  const cancelledConsultations = consultations.filter(
    (c) => c.status === ConsultationStatus.CANCELLED
  );

  const hasCancelledConsultations = Boolean(cancelledConsultations.length);

  return (
    <PageLayout>
      <Fade>
        <Section gutterBottom>
          <Title variant="h2" text={t('account.consultations.h2.future')} />
          {hasFutureConsultations && (
            <div className="flex flex-col gap-4">
              {futureConsultations.map((consultation) => (
                <ConsultationCard
                  key={consultation.id}
                  type="future"
                  consultation={consultation}
                />
              ))}
            </div>
          )}
          {!hasFutureConsultations && (
            <>
              <Paragraph gutterBottom>
                {t('account.consultations.emptyQuestionAllowed')}
              </Paragraph>
              <Link
                className="w-full flex lg:w-fit"
                to="/questions/consultation-available"
              >
                <Button
                  variant="tertiary"
                  size="large"
                  label={t('account.consultations.cta')}
                  fullWidth
                />
              </Link>
            </>
          )}
        </Section>
        {hasPastConsultations && (
          <Section>
            <Title variant="h2" text={t('account.consultations.h2.passed')} />
            <div className="flex flex-col gap-4">
              {pastConsultations.map((consultation) => (
                <ConsultationCard
                  key={consultation.id}
                  type="past"
                  consultation={consultation}
                />
              ))}
            </div>
          </Section>
        )}
        {hasCancelledConsultations && (
          <Section>
            <Title
              variant="h2"
              text={t('account.consultations.h2.cancelled')}
            />
            <div className="flex flex-col gap-4">
              {cancelledConsultations.map((consultation) => (
                <ConsultationCard
                  key={consultation.id}
                  type="past"
                  consultation={consultation}
                />
              ))}
            </div>
          </Section>
        )}
      </Fade>
    </PageLayout>
  );
}
