import { ErrorBoundary } from '@sentry/react';
import { Section } from 'components';
import GenericErrorCard from 'components/card/GenericErrorCard';
import { Fade } from 'components/transition';

import { useUnguardedSearchParams } from 'hooks';

import { PageLayout } from 'features/customer/account/content';
import { MemberQuestionsList } from 'features/questions';

const MemberCseQuestionsPage = () => {
  const defaultPage = useUnguardedSearchParams({ param: 'page' }) || '1';

  return (
    <PageLayout>
      <Section>
        <ErrorBoundary fallback={<GenericErrorCard />}>
          <Fade>
            <MemberQuestionsList
              source="cse"
              defaultPage={Number(defaultPage)}
            />
          </Fade>
        </ErrorBoundary>
      </Section>
    </PageLayout>
  );
};

export default MemberCseQuestionsPage;
