import { Moment } from 'moment';

import { privateAxios } from 'context/auth/auth.utils';

export type AddPartnerCollaboratorInput = {
  email: string;
  firstName: string;
  lastName: string;
  type: 'cse' | 'employee';
};

export type AddBulkPartnerCollaboratorInput = {
  step: number;
  file: any;
  withOnboardingEmail: boolean;
  sendOnboardingEmailDate: Moment | undefined;
};

export type AddBulkCollaboratorsResponse = {
  addedCollaboratorResult: {
    total: number;
    succeeded: {
      total: number;
    };
    failed: {
      total: number;
      errors?: {
        email: string;
        code: string;
      }[];
    };
  };
  archivedCollaboratorResult: {
    total: number;
    succeeded: {
      total: number;
    };
    failed: {
      total: number;
      errors?: {
        email: string;
        code: string;
      }[];
    };
  };
};

export type Collaborator = {
  id: string;
  customerId: number;
  principalEmail: string;
  firstName: string;
  lastName: string;
  secondaryEmail: null;
  productName: null;
  createdAt: string;
  archivedOn: string;
  type: 'cse' | 'employee';
};

export type Pagination = {
  nbItemsOnCurrentPage: number;
  currentPage: number;
  nextPage: number | null;
  previousPage: number | null;
  nbItemsPerPage: number;
  totalItems: number;
  totalPages: number;
};

export type CollaboratorsByPartnerIdResponse = {
  collaborators: Collaborator[];
  pagination: Pagination;
};

export async function getCollaboratorsByPartnerId(
  partnerId: string,
  search: string | null,
  archived = false,
  page = 1
): Promise<CollaboratorsByPartnerIdResponse> {
  const res = await privateAxios.get(
    `/partners/${partnerId}/collaborators?&page=${page}${
      search ? `&string=${search}` : ''
    }${archived ? `&archived=${archived}` : ''}`
  );

  return res.data;
}

export async function addBulkCollaborators(
  partnerId: string,
  data: AddBulkPartnerCollaboratorInput
): Promise<AddBulkCollaboratorsResponse> {
  const formData = new FormData();
  formData.append('collaborators', data.file.file);
  formData.append('withOnboardingEmail', String(data.withOnboardingEmail));
  if (data.sendOnboardingEmailDate) {
    formData.append(
      'sendOnboardingEmailDate',
      data.sendOnboardingEmailDate.toISOString(true)
    );
  }

  const res = await privateAxios.post(
    `/partners/${partnerId}/collaborators/batch`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );

  return res.data;
}

export async function deletePartnerCollaborator(
  partnerId: string,
  collaboratorId: string
) {
  const res = await privateAxios.delete(
    `/partners/${partnerId}/collaborators/${collaboratorId}`
  );

  return res.data;
}

export async function deleteBulkPartnerCollaborator(
  partnerId: string,
  collaboratorIds: string[]
) {
  await Promise.all(
    collaboratorIds.map((collaboratorId) =>
      privateAxios.delete(
        `/partners/${partnerId}/collaborators/${collaboratorId}`
      )
    )
  );
}
export async function addPartnerCollaborator(
  partnerId: string,
  data: AddPartnerCollaboratorInput
) {
  const res = await privateAxios.post(`/partners/${partnerId}/collaborators`, {
    collaborators: [data]
  });

  return res.data;
}

export async function updatePartnerCollaborator(
  partnerId: string,
  collaboratorId: string,
  data: AddPartnerCollaboratorInput
) {
  const res = await privateAxios.put(
    `/partners/${partnerId}/collaborators/${collaboratorId}`,
    {
      type: data.type
    }
  );

  return res.data;
}

export async function sendEmailInvitationToCollaborator(
  partnerId: string,
  collaboratorEmail: string
) {
  const res = await privateAxios.post(
    `/partners/${partnerId}/collaborators/emails/send`,
    {
      email: collaboratorEmail,
      type: 'onboarding'
    }
  );

  return res.data;
}

export async function sendBulkEmailInvitationToCollaborator(
  partnerId: string,
  collaboratorsEmails: string[]
) {
  await Promise.all(
    collaboratorsEmails.map((collaboratorEmail) =>
      privateAxios.post(`/partners/${partnerId}/collaborators/emails/send`, {
        email: collaboratorEmail,
        type: 'onboarding'
      })
    )
  );
}
