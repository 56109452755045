import { useOutletContext } from 'react-router-dom';

import { Consultation } from 'types/consultation';
import { Subscription } from 'types/subscription';

interface OutletContext {
  subscription: Subscription;
  consultations: Consultation[];
  futureConsultations: Consultation[];
}

export default function useCustomerAccount() {
  return useOutletContext<OutletContext>();
}
