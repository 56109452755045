import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateUnauthenticatedAppointmentVoteMutation } from 'api/customer/mutations.index';

import { ConsultationVoteReason } from 'features/rating/components';
import { LayoutCard } from 'features/shared/layout';
import { LoadingSpinner } from 'features/shared/loading';

import { Note, UnsatisfiedConsultationReasons } from '../../../services/rating';

interface ConsultationAppointmentVoteUnsatisfiedCardProps {
  ratingLinkId: string;
  note: Note;
}

export default function ConsultationAppointmentVoteUnsatisfiedCard({
  ratingLinkId,
  note
}: ConsultationAppointmentVoteUnsatisfiedCardProps) {
  const { t } = useTranslation('customer');
  const { mutate, isPending: isCreateAppointmentVoteLoading } =
    useCreateUnauthenticatedAppointmentVoteMutation();
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  const handleUnsatisfiedMutate = (
    reasonText: UnsatisfiedConsultationReasons
  ) =>
    mutate(
      { ratingLinkId, note, reason: reasonText },
      {
        onSuccess: () => setIsFormSubmitted(true)
      }
    );

  if (isFormSubmitted) {
    return (
      <LayoutCard
        title={t('account.question.vote.unsatisfied.thank')}
        body={
          <p className="text-md text-center font-medium ">
            {t('account.question.vote.unsatisifed.success')}
          </p>
        }
      />
    );
  }
  return (
    <LayoutCard
      title=""
      body={
        isCreateAppointmentVoteLoading ? (
          <div className="grid place-items-center">
            <LoadingSpinner purple />
          </div>
        ) : (
          <ConsultationVoteReason
            handleSubmitUnsatisfiedVote={handleUnsatisfiedMutate}
            isMutationLoading={isCreateAppointmentVoteLoading}
          />
        )
      }
    />
  );
}
