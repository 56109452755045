import { useMutation } from '@tanstack/react-query';
import { GoogleRating } from 'components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PublicRateAnswerPayload, rateAnswer } from 'services/rating';

import { LayoutCard } from 'features/shared/layout';
import { LoadingSpinner } from 'features/shared/loading';

import { RateAnswerInput } from './components/RateAnswerForm';

interface RateAnswerProps {
  note: RateAnswerInput['note'];
  ratingLinkId: string;
}

const AutoRateAnswer = ({ note, ratingLinkId }: RateAnswerProps) => {
  const { t } = useTranslation('customer');
  const { mutate, isSuccess, isPending, isError, error } = useMutation({
    mutationFn: (data: PublicRateAnswerPayload) => rateAnswer(data)
  });

  useEffect(() => {
    mutate({ ratingLinkId, note });
  }, []);

  const hasAlreadyRated = (error as any)?.response?.status === 400;

  if (isSuccess || hasAlreadyRated) {
    return (
      <LayoutCard
        title={`${t('general.thank')} 🥳`}
        body={
          <div className="flex flex-col gap-4">
            <GoogleRating />
          </div>
        }
      />
    );
  }

  return (
    <LayoutCard
      title={t('general.error')}
      body={
        <div className="flex flex-col gap-4">
          {isPending && <LoadingSpinner purple />}
          {isError && (
            <p className="mt-2 text-red-800 mb-4">
              {t('account.rateAnswer.form.error')}
            </p>
          )}
        </div>
      }
    />
  );
};

export default AutoRateAnswer;
