import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { Card, Divider, Title } from 'components';
import { CardContent } from 'components/card';
import QuestionSectionSkeleton from 'components/skeleton/QuestionSectionSkeleton';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MemberConsultation as MemberConsultationType,
  getMemberConsultation
} from 'services/consultation';
import { Lawyer } from 'services/question';

import RateConsultation from 'features/rating/RateConsultation';

import CancelConsultation from './CancelConsultation';
import { ConsultationDay, ConsultationSummary } from './components';
import ConsultationDropdown from './components/ConsultationDropdown';
import UploadMemberDocument from './components/UploadMemberDocument';

interface MemberConsultationProps {
  consultationId: number;
  lawyer: Lawyer;
  onGetMemberConsultation?: (consultation: MemberConsultationType) => void;
}

const MemberConsultation = ({
  consultationId,
  onGetMemberConsultation,
  lawyer
}: MemberConsultationProps) => {
  const { t } = useTranslation('customer');
  const [isCancelConsultationModalOpen, setIsCancelConsultationModalOpen] =
    useState(false);

  const {
    data: consultation,
    isLoading,
    isSuccess
  } = useQuery({
    queryKey: ['member-consultation', consultationId],
    queryFn: () => getMemberConsultation(consultationId)
  });

  useEffect(() => {
    if (isSuccess) {
      onGetMemberConsultation?.(consultation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  if (isLoading) return <QuestionSectionSkeleton />;
  if (!isSuccess) return <div />;

  const { document } = consultation;

  const canEdit = consultation.can_cancel || consultation.can_reschedule;

  return (
    <Card>
      <CardContent>
        <Title variant="h4" component="h3">
          {consultation.status === 'status_scheduled' &&
            t('account.consultation.title')}
          {consultation.status === 'status_to_reschedule' &&
            t('account.consultation.title.lawyerCanceled')}
          {consultation.status === 'status_cancelled' &&
            t('account.consultation.title.canceled')}
          {consultation.status === 'status_completed' &&
            t('account.consultation.title.completed')}
        </Title>
        <Divider />
        <Disclosure>
          <div className="flex">
            <ConsultationDay date={new Date(consultation.start_at)} />
            <ConsultationSummary
              date={new Date(consultation.start_at)}
              lawyer={lawyer}
              document={document}
              canUploadDocument={consultation.can_upload_document}
              triggerDocument={DisclosureButton}
            />
            <div className="hidden flex-col lg:flex items-end text-gray-800 flex-1 text-sm justify-between">
              {t('account.consultation.description.type')}
            </div>
          </div>
          {canEdit && (
            <ConsultationDropdown
              canReschedule={consultation.can_reschedule}
              canCancel={consultation.can_cancel}
              consultationId={consultation.id}
              questionId={consultation.question_id}
              onCancelClick={() => setIsCancelConsultationModalOpen(true)}
            />
          )}
          {consultation.can_upload_document && (
            <DisclosurePanel
              transition
              className="flex flex-col origin-top transition duration-75 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
            >
              {({ close }) => (
                <UploadMemberDocument
                  onSuccess={close}
                  consultationId={consultationId}
                />
              )}
            </DisclosurePanel>
          )}
        </Disclosure>
      </CardContent>
      {consultation.can_rate && (
        <>
          <Divider />
          <RateConsultation consultation={consultation} />
        </>
      )}
      <CancelConsultation
        isOpen={isCancelConsultationModalOpen}
        consultationId={consultationId}
        questionId={consultation.question_id}
        onClose={() => setIsCancelConsultationModalOpen(false)}
      />
    </Card>
  );
};

export default MemberConsultation;
