import { apiPublicPythonClient } from 'services/axios';

import { AuthLoginResponse } from './login';

export async function requestPasswordReset(email: string): Promise<any> {
  await apiPublicPythonClient.post('/auth/reset-password-request', {
    email
  });
}

export interface AuthResetPasswordPayload {
  token: string;
  password: string;
}

export async function resetPassword({
  token,
  password
}: AuthResetPasswordPayload): Promise<AuthLoginResponse> {
  const { data } = await apiPublicPythonClient.post<AuthLoginResponse>(
    '/auth/reset-password',
    {
      token,
      password
    }
  );

  return data;
}
