import { ArrowLeftIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { Link, To } from 'react-router-dom';

interface PageLayoutProps {
  children: ReactNode;
  previousHref?: To;
  previousLabel?: String;
}

export default function PageLayout({
  children,
  previousHref,
  previousLabel
}: PageLayoutProps) {
  return (
    <div className="mx-2">
      {previousHref && (
        <Link to={previousHref}>
          <div className="flex items-center tracking-wider uppercase gap-3 my-2">
            <div className="h-8 w-8 rounded-full bg-gray-200 stroke-black p-1 flex justify-center items-center hover:opacity-80">
              <ArrowLeftIcon className="w-4 h-4" />
            </div>
            {previousLabel || 'Retour'}
          </div>
        </Link>
      )}
      <div className={classNames('w-full')}>{children}</div>
    </div>
  );
}
