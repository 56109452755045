import { apiPrivatePythonClient } from './axios';
import { ConsultationDocument } from './document';
import { MemberQuestion } from './question';

type ConsultationStatus =
  | 'status_pending'
  | 'status_appointment_scheduled'
  | 'status_scheduled'
  | 'status_to_reschedule'
  | 'status_completed'
  | 'status_cancelled'
  | 'status_validated'
  | 'status_has_incident'
  | 'status_qualified';

export type MemberConsultation = {
  id: number;
  question_id: number;
  start_at: string;
  status: ConsultationStatus;
  document: ConsultationDocument | null;
  rating_link_id: string;
  note: number;
  can_upload_document: boolean;
  can_rate: boolean;
  can_cancel: boolean;
  can_reschedule: boolean;
};

export async function getMemberConsultation(
  consultationId: MemberConsultation['id']
): Promise<MemberConsultation> {
  const { data } = await apiPrivatePythonClient.get(
    `/member/consultations/${consultationId}`
  );

  return data;
}

export async function cancelConsultation(
  consultationId: MemberConsultation['id']
): Promise<MemberConsultation> {
  const { data } = await apiPrivatePythonClient.post(
    `/member/consultations/${consultationId}/cancel`
  );

  return data;
}

type Consultation = {
  id: number;
  question_id: MemberQuestion['id'];
  status: string;
  startAt: string;
  endAt: string;
};

export type ScheduleConsultationPayload = {
  questionId: MemberQuestion['id'];
  startAt: Date;
};

export async function createConsultation(
  data: ScheduleConsultationPayload
): Promise<Consultation> {
  const res = await apiPrivatePythonClient.post(`/member/consultations`, {
    question_id: data.questionId,
    start_at: data.startAt
  });

  return res.data;
}
