import { isEmpty } from 'lodash';
import { useEffect } from 'react';

interface UseStorageClean {
  storageParentKey: string;
  initialCleanupChildKeys: string[];
  storage: Storage;
}

const useStorageClean = ({
  storageParentKey,
  initialCleanupChildKeys,
  storage
}: UseStorageClean) => {
  const getStorage = () => storage || window.sessionStorage;

  const parentStorage = getStorage().getItem(storageParentKey);

  const clearItemInStorage = async (storageChildKey: string) => {
    if (parentStorage) {
      const parsedStorage = JSON.parse(parentStorage);
      await delete parsedStorage[parseInt(storageChildKey, 10)];
      if (isEmpty(storage)) {
        getStorage().removeItem(storageParentKey);
      } else {
        getStorage().setItem(storageParentKey, JSON.stringify(parsedStorage));
      }
    }
    return getStorage().getItem(storageParentKey);
  };

  useEffect(() => {
    if (parentStorage) {
      const parsedStorage = JSON.parse(parentStorage);
      if (!initialCleanupChildKeys.length) {
        getStorage().removeItem(storageParentKey);
      }
      const keysToDelete = Object.keys(parsedStorage).filter(
        (k) => !initialCleanupChildKeys.includes(k)
      );
      if (keysToDelete.length) {
        keysToDelete.forEach((k) => clearItemInStorage(k));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCleanupChildKeys, parentStorage, storageParentKey]);

  return {
    clearItemInStorage: (storageChildKey: string) =>
      clearItemInStorage(storageChildKey)
  };
};

export default useStorageClean;
