import classNames from 'classnames';
import { Dispatch, useState } from 'react';

interface RequestStepLayoutProps {
  icon?: React.ElementType;
  defaultType?: 'info' | 'action' | 'important' | 'error';
  className?: string;
  children:
    | React.ReactNode
    | ((props: {
        type: 'info' | 'action' | 'important' | 'error';
        setType: Dispatch<
          React.SetStateAction<'info' | 'action' | 'important' | 'error'>
        >;
      }) => React.ReactNode);
}

const RequestStepLayout = ({
  icon,
  children,
  defaultType = 'important',
  className
}: RequestStepLayoutProps) => {
  const [type, setType] = useState(defaultType);
  const Icon = icon;

  return (
    <li
      className={classNames(
        'ms-5 md:ms-6',
        type === 'info' ? 'mb-4' : 'mb-8',
        className
      )}
    >
      <span
        className={classNames(
          'absolute mt-4 flex items-center justify-center rounded-full ring-8 ring-white',
          icon ? 'w-6 h-6' : 'w-3.5 h-3.5',
          {
            'bg-purple-800 -start-3.5': type === 'important',
            'bg-purple-300 -start-2': type === 'info',
            'w-5 h-5 border-purple-800 border-4 bg-white -start-2.5':
              type === 'action',
            'bg-red-800 -start-2': type === 'error'
          }
        )}
      >
        {Icon && <Icon className="h-4 w-4 text-white" aria-hidden="true" />}
      </span>
      {typeof children === 'function' ? children({ type, setType }) : children}
    </li>
  );
};

export default RequestStepLayout;
