import { Card, CardContent } from 'components/card';
import { capitalize } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MemberQuestion, QuestionSource } from 'services/question';

import { useCreateHref, useLocale } from 'hooks';

import { Button } from 'features/shared/buttons';

import { formatDateToIntl } from 'utils/date';

interface MemberQuestionOverviewProps {
  question: Omit<MemberQuestion, 'answer_id' | 'additional_request_id'>;
  source?: QuestionSource;
}

const MemberQuestionOverview = ({
  question,
  source = 'personal'
}: MemberQuestionOverviewProps) => {
  const { locale } = useLocale();
  const { t } = useTranslation('customer');
  const { href } = useCreateHref({
    path: '/account/consultations/booking',
    params: {
      questionId: question.id,
      lawyerId: question.lawyer?.id
    }
  });

  const hasActions = question.can_ask_consultation || question.can_rate_answer;

  return (
    <Card className="max-w-[720px]">
      <CardContent>
        <span className="text-md break-words line-clamp-2 font-normal">
          {capitalize(question.content)}
        </span>
        <div className="my-5 border-base border-gray-500" />
        <Trans
          parent="div"
          ns="customer"
          className="truncate mb-1 text-gray-700"
          i18nKey="account.questions.askedThe"
          components={{ 1: <span className="font-medium text-gray-800" /> }}
          values={{
            date: formatDateToIntl(
              new Date(question.created_at),
              'long',
              locale
            )
          }}
        />
        <Trans
          parent="div"
          ns="customer"
          className="truncate mb-1 text-gray-700"
          i18nKey="account.questions.status.label"
          components={{ 1: <span className="font-medium text-gray-800" /> }}
          values={{
            status: t(`account.request.status.${question.status}`)
          }}
        />
        {source === 'cse' && (
          <Trans
            parent="div"
            ns="customer"
            className="truncate mb-1 text-gray-700"
            i18nKey="account.questions.by"
            components={{ 1: <span className="font-medium text-gray-800" /> }}
            values={{
              name: question.member_full_name
            }}
          />
        )}
        {hasActions && (
          <div className="mt-10 w-full lg:w-fit flex flex-col sm:flex-row gap-4">
            {question.can_ask_consultation && (
              <Link className="w-full lg:w-fit flex-1" to={href}>
                <Button
                  fullWidth
                  label={t('account.question.askConsultation')}
                  variant="tertiary"
                  size="medium"
                />
              </Link>
            )}
            {question.can_rate_answer && (
              <Link to={`/questions/${question.id}`}>
                <Button
                  fullWidth
                  label={t('account.question.vote.cta')}
                  variant="tertiary"
                  size="medium"
                />
              </Link>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default MemberQuestionOverview;
