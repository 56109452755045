import { useMutation } from '@tanstack/react-query';

import {
  Note,
  PublicRateConsultationPayload,
  rateConsultationAppointment
} from '../../../services/rating';

export function useCreateUnauthenticatedAppointmentVoteMutation() {
  return useMutation({
    mutationFn: ({
      ratingLinkId,
      note,
      reason
    }: {
      ratingLinkId: string;
      note: Note;
      reason?: PublicRateConsultationPayload['reason'];
    }) => rateConsultationAppointment({ ratingLinkId, note, reason })
  });
}
