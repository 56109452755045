import { Card } from 'components';

interface ArticleListSkeletonProps {
  number?: number;
}

const ArticleListSkeleton = ({ number = 6 }: ArticleListSkeletonProps) => (
  <div className="grid sm:grid-cols-4 xl:grid-cols-3 gap-5 xl:mb-10">
    {Array(number)
      .fill(1)
      .map(() => (
        <Card
          compact
          variant="transparent"
          className="sm:col-span-2 xl:col-span-1"
        >
          <div className="flex flex-col gap-3">
            <div className="animate-pulse h-36 bg-gray-300 rounded" />
            <div className="animate-pulse h-6 w-36 bg-gray-300 rounded" />
            <div className="animate-pulse h-10 bg-gray-300 rounded" />
          </div>
        </Card>
      ))}
  </div>
);

export default ArticleListSkeleton;
