import { Navigate, Outlet, useLocation } from 'react-router-dom';
import {
  MEMBER_DEFAULT_REDIRECT_URL,
  PUBLIC_DEFAULT_REDIRECT_URL
} from 'routes/constants';

import { useAuth } from 'context';

const MemberProtectedRoute = ({ admin = false, cse = false }: any) => {
  const { member } = useAuth();
  const location = useLocation();

  if (!member)
    return (
      <Navigate
        state={{ from: location }}
        to={`${PUBLIC_DEFAULT_REDIRECT_URL}?password=true&origin=${location.pathname}`}
        replace
      />
    );

  if (cse && !member.is_active_cse_member)
    return <Navigate to={MEMBER_DEFAULT_REDIRECT_URL} replace />;

  if (admin && !member.is_admin)
    return <Navigate to={MEMBER_DEFAULT_REDIRECT_URL} replace />;

  return <Outlet />;
};

export default MemberProtectedRoute;
