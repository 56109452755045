import { Navigate, Outlet } from 'react-router-dom';
import { MEMBER_DEFAULT_REDIRECT_URL } from 'routes/constants';

import { useAuth } from 'context';

const PublicRoute = () => {
  const { member } = useAuth();

  if (member) return <Navigate to={MEMBER_DEFAULT_REDIRECT_URL} replace />;

  return <Outlet />;
};

export default PublicRoute;
