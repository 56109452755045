import { useParams, useSearchParams } from 'react-router-dom';

import {
  ConsultationAppointmentVoteSatisfiedCard,
  ConsultationAppointmentVoteUnsatisfiedCard
} from 'features/customer/consultation';
import { LayoutDefault } from 'features/shared/layout';

import { Note, isPositiveRating } from '../../services/rating';

export default function ConsultationAppointmentVotePage() {
  const params = useParams();
  const voteLinkId = params.voteLinkId || '';
  const [searchParams] = useSearchParams();
  const noteFromParams = searchParams.get('note') || '5';
  const note = parseInt(noteFromParams, 10) as Note;
  const isSatisfied = isPositiveRating(note);

  return (
    <LayoutDefault>
      {isSatisfied ? (
        <ConsultationAppointmentVoteSatisfiedCard
          ratingLinkId={voteLinkId}
          note={note}
        />
      ) : (
        <ConsultationAppointmentVoteUnsatisfiedCard
          ratingLinkId={voteLinkId}
          note={note}
        />
      )}
    </LayoutDefault>
  );
}
