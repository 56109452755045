import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';

interface TagProps {
  text?: ReactNode;
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'transparent'
    | 'none'
    | 'error'
    | 'success';
  className?: string;
}

function Tag({
  text,
  children,
  variant = 'primary',
  className = ''
}: PropsWithChildren<TagProps>) {
  return (
    <div
      className={classNames(
        className,
        'rounded-xl h-fit w-fit line-clamp-1 font-semibold uppercase text-sm',
        variant === 'none' ? 'px-0' : 'px-4 py-0.5',
        {
          'border-gray-900 text-gray-900 bg-gray-100': variant === 'primary',
          'text-purple-800 font-semibold border-purple-900':
            variant === 'secondary',
          'text-white bg-gray-700': variant === 'tertiary',
          'bg-transparent text-black border-gray-700':
            variant === 'transparent',
          'text-black border-0': variant === 'none',
          'text-green-800 bg-green-200': variant === 'success',
          'text-red-800 bg-red-200': variant === 'error'
        }
      )}
    >
      {children || text}
    </div>
  );
}

export default Tag;
