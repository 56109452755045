import { apiPrivatePythonClient } from 'services/axios';

import { setNewAuthStorage } from 'context/auth/auth.utils';

interface AuthRefreshTokenResponse {
  access_token: string;
  refresh_token: string;
}

export const getAccessTokenByRefreshToken =
  async (): Promise<AuthRefreshTokenResponse> => {
    try {
      const { data } =
        await apiPrivatePythonClient.post<AuthRefreshTokenResponse>(
          '/auth/token/refresh',
          {
            refresh_token: window.localStorage.getItem('refreshToken')
          }
        );

      setNewAuthStorage({
        accessToken: data.access_token,
        refreshToken: data.refresh_token
      });

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
