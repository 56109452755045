import { Title } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';

import { UnsatisfiedConsultationReasons } from '../../../services/rating';

interface ConsultationVoteModalUnsatisfiedProps {
  handleSubmitUnsatisfiedVote: (reason: UnsatisfiedConsultationReasons) => void;
  isMutationLoading: boolean;
}

function ConsultationVoteReason({
  handleSubmitUnsatisfiedVote,
  isMutationLoading
}: ConsultationVoteModalUnsatisfiedProps) {
  const { t } = useTranslation('customer');
  const [reasonText, setReasonText] = useState<string>('');

  return (
    <div>
      <Title variant="h4" component="h3" gutterBottom>
        {t('account.consultation.vote.unsatisfied.title')}
      </Title>
      <Paragraph className="text-sm text-left font-medium text-gray-700">
        {t('account.consultation.vote.unsatisfied.paragraph')}
      </Paragraph>
      <div className="mt-2">
        <select
          value={reasonText}
          onChange={(e) => setReasonText(e.target.value)}
          className="mt-1 w-full pl-3 pr-10 py-2placeholder:text-gray-700 text-sm md:text-base block focus:ring-0 focus:ring-offset-0 focus:outline-none py-3 px-4 rounded focus:drop-shadow-input focus:border-purple-900 focus:shadow-purple-900"
        >
          <option value="" disabled>
            {t('account.consultation.vote.unsatisfied.reason.placeholder')}
          </option>

          {[
            {
              id: 1,
              value: 'no_show',
              label: t('account.consultation.vote.unsatisfied.reason.no_show')
            },
            {
              id: 2,
              value: 'late',
              label: t('account.consultation.vote.unsatisfied.reason.late')
            },
            {
              id: 3,
              value: 'unsatisfying_answers',
              label: t(
                'account.consultation.vote.unsatisfied.reason.unsatisfying_answers'
              )
            },
            {
              id: 4,
              value: 'other',
              label: t('account.consultation.vote.unsatisfied.reason.other')
            }
          ].map((consultationUnsatisfiedReason) => (
            <option
              key={consultationUnsatisfiedReason.id}
              value={consultationUnsatisfiedReason.value}
            >
              {consultationUnsatisfiedReason.label}
            </option>
          ))}
        </select>
      </div>
      <div className="mt-2 flex justify-end">
        <Button
          submit
          variant="primary"
          size="small"
          label={t('general.send')}
          isLoading={isMutationLoading}
          onClick={() =>
            handleSubmitUnsatisfiedVote(
              reasonText as UnsatisfiedConsultationReasons
            )
          }
        />
      </div>
    </div>
  );
}

export default ConsultationVoteReason;
