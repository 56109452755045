import { format } from 'date-fns';

import { useLocale } from 'hooks';

interface ConsultationDayProps {
  date: Date;
}

const ConsultationDay = ({ date }: ConsultationDayProps) => {
  const { dateLocale } = useLocale();

  return (
    <div className="flex flex-col gap-px justify-center text-center w-fit font-bold pr-4 md:px-6 border-r border-gray-400">
      <div className="text-base capitalize text-gray-800">
        {format(date, 'EEEE', {
          locale: dateLocale
        })}
      </div>
      <div className="text-3xl">
        {format(date, 'dd', {
          locale: dateLocale
        })}
      </div>
      <div className="text-sm capitalize text-gray-800">
        {format(date, 'LLLL', {
          locale: dateLocale
        })}
      </div>
    </div>
  );
};

export default ConsultationDay;
