import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { uploadMemberDocumentByConsultation } from 'services/document';

import { DocumentDropzone } from 'features/customer/account/consultations/booking';
import { Button } from 'features/shared/buttons';
import { Toast } from 'features/shared/toasts';

import { ConsultationFile } from 'types/consultation';

interface UploadMemberDocumentProps {
  consultationId: number;
  onSuccess: () => void;
}

const UploadMemberDocument = ({
  consultationId,
  onSuccess
}: UploadMemberDocumentProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('customer');
  const [uploadedDocument, setDocument] = useState<
    ConsultationFile | undefined
  >();
  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      uploadMemberDocumentByConsultation(consultationId, uploadedDocument),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['member-consultation', consultationId]
      });
      onSuccess?.();
    },
    onError: (e) => {
      toast.custom(
        <Toast
          type="error"
          title={t('general.error')}
          message={t('general.error')}
        />,
        { position: 'bottom-left', duration: 3000 }
      );
    }
  });

  return (
    <>
      <DocumentDropzone
        label="label.uploadFile"
        typeLabel="file.upload.3"
        required
        document={uploadedDocument}
        setDocument={setDocument}
        maxFile
      />
      <Button
        size="small"
        variant="tertiary"
        onClick={() => mutate()}
        disabled={isPending || !uploadedDocument}
        className="w-fit self-end"
      >
        {t('file.upload.submit')}
      </Button>
    </>
  );
};

export default UploadMemberDocument;
