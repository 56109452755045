import { PencilAltIcon, PlusIcon } from '@heroicons/react/outline';
import { ClockIcon } from '@heroicons/react/solid';
import { addMinutes } from 'date-fns';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ConsultationDocument } from 'services/document';
import { Lawyer } from 'services/question';

import { useLocale } from 'hooks';

import { getDateAsHhMm } from 'utils/date';

interface ConsultationSummaryProps {
  document: ConsultationDocument | null;
  canUploadDocument: boolean;
  lawyer: Lawyer;
  date: Date;
  triggerDocument: React.ElementType;
}

const ConsultationSummary = ({
  lawyer,
  document,
  date,
  canUploadDocument,
  triggerDocument
}: ConsultationSummaryProps) => {
  const { t } = useTranslation('customer');
  const { locale } = useLocale();
  const DocumentComponent = triggerDocument;

  return (
    <div className="px-4 flex flex-1 flex-col gap-4 justify-around">
      <div className="flex flex-1 items-center text-base">
        <span className="inline-flex ml-0.5 mr-2.5 h-5 w-5 items-center justify-center rounded-full bg-gray-600">
          <span className="text-sm font-medium leading-none text-white">
            {capitalize(lawyer.full_name)[0]}
          </span>
        </span>
        {t('account.consultation.description.lawyer', {
          lawyerFullName: lawyer.full_name
        })}
      </div>
      <div className="flex flex-1 items-center text-base font-medium">
        <ClockIcon className="w-6 h-6 mr-2 text-gray-600" />
        {getDateAsHhMm(date, locale)} -{' '}
        {getDateAsHhMm(addMinutes(date, 30), locale)}
      </div>
      <div className="flex ml-1 items-center text-base">
        {document && (
          <a
            rel="noreferrer"
            target="_blank"
            href={document.url}
            className="underline text-purple-800"
          >
            {t('account.consultation.description.documentUploaded')}
          </a>
        )}
        {!document && canUploadDocument && (
          <DocumentComponent className="text-left text-purple-800 cursor-pointer">
            {t('account.consultation.description.documentAdd')}
          </DocumentComponent>
        )}
        {!document &&
          !canUploadDocument &&
          t('account.consultation.description.documentEmpty')}
        {document && canUploadDocument && (
          <DocumentComponent
            as={document ? PencilAltIcon : PlusIcon}
            className="ml-2 w-5 h-5 text-purple-800 cursor-pointer"
          />
        )}
      </div>
    </div>
  );
};

export default ConsultationSummary;
