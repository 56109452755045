import { useQuery } from '@tanstack/react-query';
import { Card, Divider } from 'components';
import { CardContent } from 'components/card';
import { AnswerSectionSkeleton } from 'components/skeleton';
import { ReactNode } from 'react';
import { getMemberQuestionAnswer } from 'services/answer';
import { Lawyer, MemberQuestion } from 'services/question';

import { useLocale } from 'hooks';

import SanitizedContent from 'features/customer/question/SanitizedContent';
import LawyerIdentity from 'features/lawyer/components/LawyerIdentity';

import { formatDateToIntl } from 'utils/date';

interface QuestionAnswerProps {
  answerId: MemberQuestion['answer_id'];
  footer: ReactNode;
  lawyer: Lawyer | null;
}

const QuestionAnswer = ({ answerId, lawyer, footer }: QuestionAnswerProps) => {
  const { locale } = useLocale();
  const {
    data: answer,
    isLoading,
    isSuccess
  } = useQuery({
    queryKey: ['member-question-answer', answerId],
    queryFn: () => getMemberQuestionAnswer(answerId as number)
  });

  if (isLoading) return <AnswerSectionSkeleton />;
  if (!isSuccess) return <div />;

  return (
    <Card variant="secondary">
      <CardContent>
        <LawyerIdentity fullName={lawyer?.full_name || null} />
        <Divider />
        <div className="my-4">
          <SanitizedContent content={answer.content} />
        </div>
        <div className="mt-2 text-right text-sm font-medium text-gray-700">
          {formatDateToIntl(new Date(answer.published_at), 'long', locale)}
        </div>
      </CardContent>
      {footer && <Divider className="mb-4" />}
      {footer}
    </Card>
  );
};

export default QuestionAnswer;
