import { Divider, Title } from 'components';
import { Card, CardContent } from 'components/card';
import { capitalize } from 'lodash';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionAnswer } from 'services/answer';

import { useLocale } from 'hooks';

import { formatDateToIntl } from 'utils/date';

import SanitizedContent from '../customer/question/SanitizedContent';

interface AnswerCardProps {
  answer: Omit<QuestionAnswer, 'question_id' | 'additional_request_id'>;
  lawyerFullName: string | null;
}

export default function AnswerCard({
  answer,
  lawyerFullName,
  children
}: PropsWithChildren<AnswerCardProps>) {
  const { locale } = useLocale();

  return (
    <Card variant="secondary">
      <CardContent>
        <LawyerInfo fullName={lawyerFullName} />
        <Divider />
        <div className="my-4">
          <SanitizedContent content={answer.content} />
        </div>
        <div className="mt-2 text-right text-sm font-medium text-gray-700">
          {formatDateToIntl(new Date(answer.published_at), 'long', locale)}
        </div>
      </CardContent>
      {children}
    </Card>
  );
}

export function LawyerInfo({
  fullName,
  avatarUrl
}: {
  fullName: string | null;
  avatarUrl?: string | null;
}) {
  const { t } = useTranslation('customer');

  return (
    <div className="flex items-center">
      <div className="mr-2 flex-shrink-0 flex items-center">
        {!fullName && (
          <span className="inline-block h-6 w-6 overflow-hidden rounded-full bg-gray-100">
            <svg
              className="h-full w-full text-gray-300"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
        )}
        {fullName && avatarUrl && (
          <img
            className="h-6 w-6 rounded-full"
            src={avatarUrl}
            alt={fullName}
          />
        )}
        {fullName && !avatarUrl && (
          <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-500">
            <span className="text-sm font-medium leading-none text-white">
              {capitalize(fullName)[0]}
            </span>
          </span>
        )}
      </div>
      <Title variant="h4" component="h3">
        {fullName
          ? t('account.question.answer.title', {
              lawyerFullName: fullName
            })
          : t('account.question.answer.ekielegalteam')}
      </Title>
    </div>
  );
}
