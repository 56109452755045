import { Title } from 'components';
import { useTranslation } from 'react-i18next';
import { Note } from 'services/rating';

import { VoteButton } from 'features/customer/vote';

function ConsultationVoteChoice({
  handleConsultationVote
}: {
  handleConsultationVote: (value: Note) => void;
}) {
  const { t } = useTranslation('customer');

  return (
    <div>
      <Title variant="h4" component="h3">
        {t('account.consultation.vote.paragraph')}
      </Title>
      <div className="mt-2">
        <div className="grid grid-rows-vote max-w-fit">
          <div className="grid grid-cols-5 gap-4 w-full">
            <VoteButton
              vote={1}
              handleClick={() => handleConsultationVote(1)}
            />
            <VoteButton
              vote={2}
              handleClick={() => handleConsultationVote(2)}
            />
            <VoteButton
              vote={3}
              handleClick={() => handleConsultationVote(3)}
            />
            <VoteButton
              vote={4}
              handleClick={() => handleConsultationVote(4)}
            />
            <VoteButton
              vote={5}
              handleClick={() => handleConsultationVote(5)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsultationVoteChoice;
