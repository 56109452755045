import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Title } from 'components';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { MemberConsultation, cancelConsultation } from 'services/consultation';

import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';
import { Toast } from 'features/shared/toasts';

interface CancelConsultationProps {
  questionId: MemberConsultation['question_id'];
  consultationId: MemberConsultation['id'];
  isOpen: boolean;
  onClose: () => void;
}

const CancelConsultation = ({
  consultationId,
  questionId,
  isOpen,
  onClose
}: CancelConsultationProps) => {
  const { t } = useTranslation('customer');
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationKey: ['member-consultation-cancel', consultationId],
    mutationFn: () => cancelConsultation(consultationId),
    onSuccess: () => {
      toast.custom(
        <Toast
          type="success"
          title={t('account.consultation.cancel.success.title')}
          message={t('account.consultation.cancel.success.message')}
        />,
        { position: 'bottom-left', duration: 3000 }
      );
      queryClient.invalidateQueries({
        queryKey: ['member-consultation', consultationId]
      });
      queryClient.invalidateQueries({
        queryKey: ['member-question', questionId]
      });
      onClose();
    }
  });

  return (
    <LayoutModal isModalOpen={isOpen} handleModalClose={onClose}>
      <div>
        <div className="text-left">
          <Title variant="h3">{t('account.consultation.cancel.title')}</Title>
        </div>
        <div className="text-left">
          <p className="text-md mt-2 font-medium">
            {t('account.consultation.cancel.description')}
          </p>
        </div>
        <div className="mt-6">
          <div className="flex gap-4 justify-end">
            <Button
              variant="secondary"
              size="medium"
              label={t('account.consultation.cancel.close')}
              onClick={onClose}
            />
            <Button
              submit
              variant="tertiary"
              size="medium"
              label={t('account.consultation.cancel.submit')}
              onClick={() => mutate()}
            />
          </div>
        </div>
      </div>
    </LayoutModal>
  );
};

export default CancelConsultation;
