import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { RateAnswerInput } from 'features/rating/RateAnswer';

interface VoteButtonProps {
  vote: RateAnswerInput['note'];
  handleClick: (vote: RateAnswerInput['note']) => void;
}

interface VoteEmojiButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  description?: string;
  left?: boolean;
}

function ButtonWrapper({
  children,
  description,
  left = true,
  ...props
}: PropsWithChildren<VoteEmojiButtonProps>) {
  return (
    <div className="flex flex-col gap-1 text-center">
      <button
        type="button"
        className="font-bold text-lg p-2 px-4 rounded bg-white border-2 border-gray-300 hover:bg-purple-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-800"
        {...props}
      >
        {children}
      </button>
      <span
        className={classNames(
          'hidden md:block text-sm',
          left ? 'text-left' : 'text-right'
        )}
      >
        {description}
      </span>
    </div>
  );
}

export default function VoteButton({ vote, handleClick }: VoteButtonProps) {
  const { t } = useTranslation('customer');
  switch (vote) {
    case 1:
      return (
        <ButtonWrapper
          description={t('account.question.answer.vote.hasNotAnswered')}
          onClick={() => handleClick(vote)}
        >
          1
        </ButtonWrapper>
      );
    case 2:
      return <ButtonWrapper onClick={() => handleClick(vote)}>2</ButtonWrapper>;
    case 3:
      return <ButtonWrapper onClick={() => handleClick(vote)}>3</ButtonWrapper>;
    case 4:
      return <ButtonWrapper onClick={() => handleClick(vote)}>4</ButtonWrapper>;
    case 5:
      return (
        <ButtonWrapper
          left={false}
          description={t('account.question.answer.vote.hasAnswered')}
          onClick={() => handleClick(vote)}
        >
          5
        </ButtonWrapper>
      );
    default:
      throw Error('Vote not handled');
  }
}
