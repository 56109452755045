import { apiPublicPythonClient } from './axios';

export type Note = 0 | 1 | 2 | 3 | 4 | 5;

export interface PublicRateAnswerPayload {
  ratingLinkId: string;
  note: Note;
  comment?: string;
}

export async function rateAnswer({
  ratingLinkId,
  note,
  comment
}: PublicRateAnswerPayload) {
  const { data } = await apiPublicPythonClient.post(
    `/public/answer-ratings/link/${ratingLinkId}`,
    {
      note,
      comment
    }
  );

  return data;
}

export type UnsatisfiedConsultationReasons =
  | 'late'
  | 'no_show'
  | 'unsatisfying_answers'
  | 'other';

export interface PublicRateConsultationPayload {
  ratingLinkId: string;
  note: Note;
  reason?: UnsatisfiedConsultationReasons;
}

export async function rateConsultationAppointment({
  ratingLinkId,
  note,
  reason
}: PublicRateConsultationPayload) {
  const { data } = await apiPublicPythonClient.post(
    `/public/consultation-ratings/link/${ratingLinkId}`,
    {
      note,
      reason
    }
  );

  return data;
}

export function isPositiveRating(note: Note) {
  return note === 4 || note === 5;
}
